// src/Components/Column.js
import React from 'react';
import { ColumnContainer, ColumnTitle, NumberList } from './styles';

const Column = ({ children, color, title, onTitleClick, titleSize, titleColor, columns }) => {
  return (
    <ColumnContainer color={color}>
      <ColumnTitle color={titleColor} titleColor={titleColor} size={titleSize} onClick={onTitleClick}>
        {title}
      </ColumnTitle>
      <NumberList columns={columns}>
        {children}
      </NumberList>
    </ColumnContainer>
  );
};

export default Column;
