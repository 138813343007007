// src/App.js
import React, { useEffect, useState } from 'react';
import Column from './Components/Column';
import NumberItem from './Components/NumberItem';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import SettingsModal from './Components/SettingsModal';
import axios from 'axios';

const AppContainer = styled.div`
  display: flex;
`;

const App = () => {
  const [leftNumbers, setLeftNumbers] = useState([]);
  const [rightNumbers, setRightNumbers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orders, setOrders] = useState([]);
  const [restaurantLicense, setRestaurantLicense] = useState();
  const [settings, setSettings] = useState({
    numberSize: 2,
    columns: 1,
    titleSize: 1.5,
  });

  useEffect(() => {
    //if restaurant license is not set, show modal to set it
    
    // Load settings from localStorage if available
    const savedSettings = JSON.parse(localStorage.getItem('settings'));
    //from json to object
     if (savedSettings) {
      console.log('savedSettings', savedSettings.licenca);
      if (savedSettings.licenca) {
        setRestaurantLicense(savedSettings.licenca);
      }else{
        setIsModalOpen(true);
      }
      try {
         setSettings((prevSettings) => ({
          ...prevSettings,
          ...savedSettings,
        }));
      } catch (e) {
        console.error('Error parsing settings from localStorage:', e);
      }
    }
  }, []);

  useEffect(() => {
    // Fetch numbers from the API or mock data
     
 
  }, []);
  const getNewOrders = async (license) => {
    try {
      const response = await axios.get(`https://api.itable.app/api/kuhinja/${license}/get_json_new_tv`);
       console.log('orde', response);
      if (response.data.length == 0){
       }else{
        //for left will be set kiosk_id only last 4 digits and all from reposne status Accepted
        let sortedl = response.data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

        let left = sortedl.filter((order) => order.status == 'Accepted').map((order) => order.kiosk_id.toString().slice(-4));
 
        if(left.length > 0){
          setLeftNumbers(left);
        }
        //for right will be set kiosk_id only last 4 digits and all from reposne status Finished
        //sord desc by updated_at

        let sorted = response.data.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));


        let right = sorted.filter((order) => order.status == 'Finished').map((order) => order.kiosk_id.toString().slice(-4));
        //get only last 4 digits from kiosk_id
        
        //now show only last 4 digits
         if(right.length > 0){
          
          setRightNumbers(right);
        }
        
        }
      
      
    } catch (error) {
      console.error('Failed to fetch orders:', error);
    }
  }
  useEffect(() => {
    const savedSettings = JSON.parse(localStorage.getItem('settings'));
    if(savedSettings?.licenca){
    getNewOrders(savedSettings.licenca);
    }
    const intervalId = setInterval(() => {
    

      if(savedSettings?.licenca){
      fetchOrders(savedSettings.licenca)
      }else{
        console.log('restaurantLicense', restaurantLicense);
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, [orders]);

  const fetchOrders = async (license) => {
    try {
      const response = await axios.get(`https://api.itable.app/api/kuhinja/${license}/get_last_order_time`);
     
       if (response.data.time){
  
        if(localStorage.getItem('lastOrder') != response.data.time){
   
          getNewOrders(license).then(() => {
            localStorage.setItem('lastOrder', response.data.time);
          }
          
         
        
           
        )
       
        }
        
        
      }
  
     
       /*  if(orders.filter(order => order.id == localStorage.getItem('lastOrder')).length == 0){
          getNewOrders(license).then(() => {
            localStorage.setItem('lastOrder', response.data.last_done?.id);
          }
          );
        }
  
        if(orders1.filter(order => order.id == localStorage.getItem('lastDone')).length == 0){
          getNewOrdersDone(license).then(() => {
            localStorage.setItem('lastDone', response.data.last_done?.id);
          }
          );
        } */
     
    } catch (error) {
      console.error('Failed to fetch orders:', error);
    }
  };

  useEffect(() => {
    // Move numbers from left to right if they are in both lists
    const interval = setInterval(() => {
      setLeftNumbers((prevLeftNumbers) => {
        const intersection = prevLeftNumbers.filter((num) =>
          rightNumbers.includes(num)
        );

        if (intersection.length > 0) {
          const newLeftNumbers = prevLeftNumbers.filter(
            (num) => !rightNumbers.includes(num)
          );
           // Play bell sound when a number moves
         //  const bellAudio = new Audio('/bell.wav');
         //  bellAudio.play();
          return newLeftNumbers;
        }

        return prevLeftNumbers;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [rightNumbers]);

  const handleTitleClick = () => {
    setIsModalOpen(true);
  };

  const handleSaveSettings = (newSettings) => {
    setSettings(newSettings);
    localStorage.setItem('settings', JSON.stringify(newSettings));
  };

  return (
    <>
      <AppContainer>
        <Column
          color="white"
          titleColor="orange"
          title="U PRIPREMI"
          titleSize={settings.titleSize}
          columns={settings.columns}
        >
          <AnimatePresence>
            {leftNumbers.map((num) => (
              <NumberItem key={num} number={num} color="orange" size={settings.numberSize} />
            ))}
          </AnimatePresence>
        </Column>
        <Column
          color="white"
          titleColor="blue"
          title="SPREMNO"
          titleSize={settings.titleSize}
          onTitleClick={handleTitleClick}
          columns={settings.columns}
        >
          <AnimatePresence>
            {rightNumbers.map((num) => (
              <NumberItem key={num} number={num} color="blue" size={settings.numberSize} />
            ))}
          </AnimatePresence>
        </Column>
      </AppContainer>
      <SettingsModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        onSave={handleSaveSettings}
      />
    </>
  );
};

export default App;
