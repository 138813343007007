// src/Components/styles.js
import styled from 'styled-components';

export const ColumnContainer = styled.div`
  flex: 1;
  padding: 10px;
  background-color: ${(props) => props.color};
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;


export const ColumnTitle = styled.div`
  width: 100%;
  text-align: center;
  font-size: ${(props) => props.size}rem;
  background-color: ${(props) => props.color};
  color: ${(props) => 'white'};
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
`;

export const NumberList = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  gap: 1px;
  margin: 0px;
  width: 100%;
  justify-items: center;
  transition: grid-template-columns 0.3s ease;
`;
