// src/Components/NumberItem.js
import React from 'react';
import { motion } from 'framer-motion';

const NumberItem = ({ number, color, size }) => {
  return (
    <motion.div
      style={{
        color: color,
        width: `${size/2}rem`,
        height: `${size/2}rem`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        padding: '5px',
        borderRadius: '5px',
         fontSize: `${size / 2}rem`,
      }}
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
    >
      {number}
    </motion.div>
  );
};

export default NumberItem;
