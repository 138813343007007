// src/Components/SettingsModal.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

// Styling for the modal and its content
const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 400px;
  max-width: 90vw;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const Title = styled.h2`
  margin-bottom: 20px;
  color: #333;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
  color: ${props => (props.red ? 'red' : '#333')};
`;

const Input = styled.input`
  display: block;
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: ${props => (props.red ? 'red' : '#333')};
`;

const SaveButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const SettingsModal = ({ isOpen, onRequestClose, onSave }) => {
  const [numberSize, setNumberSize] = useState(2);
  const [columns, setColumns] = useState(1);
  const [titleSize, setTitleSize] = useState(1.5);
  const [licenca, setLicenca] = useState('');

  // Get settings from localStorage
  useEffect(() => {
    const savedSettings = localStorage.getItem('settings');
    if (savedSettings) {
      try {
        const parsedSettings = JSON.parse(savedSettings);
        setNumberSize(parsedSettings.numberSize || 2);
        setColumns(parsedSettings.columns || 1);
        setTitleSize(parsedSettings.titleSize || 1.5);
        setLicenca(parsedSettings.licenca || '');
      } catch (e) {
        console.error('Error parsing settings from localStorage:', e);
      }
    }
  }, []);

  const handleSave = () => {
    onSave({ numberSize, columns, titleSize, licenca });
    onRequestClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={{
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        padding: '0',
        border: 'none',
        borderRadius: '8px',
      }
    }}>
      <ModalContainer>
        <Title>Podešavanje</Title>
        <div>
          <Label red>
            LICENCA
            <Input
              type="text"
              value={licenca}
              onChange={(e) => setLicenca(e.target.value)}
              red
            />
          </Label>
        </div>

        <div>
          <Label>
            Veličina broja:
            <Input
              type="number"
              value={numberSize}
              onChange={(e) => setNumberSize(Number(e.target.value))}
            />
          </Label>
        </div>
        <div>
          <Label>
            Kolone:
            <Input
              type="number"
              value={columns}
              onChange={(e) => setColumns(Number(e.target.value))}
            />
          </Label>
        </div>
        <div>
          <Label>
            Veličina naslova:
            <Input
              type="number"
              value={titleSize}
              onChange={(e) => setTitleSize(Number(e.target.value))}
            />
          </Label>
        </div>
        <SaveButton onClick={handleSave}>Save</SaveButton>
      </ModalContainer>
    </Modal>
  );
};

export default SettingsModal;
